*,
*:after,
*:before {
  box-sizing: border-box;
  font: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

::-webkit-scrollbar {
  display: none;
}

.unlock-enter {
  opacity: 0;
  transform: scale(0.5);
}

.unlock-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.unlock-exit {
  opacity: 1;
  transform: scale(1);
}

.unlock-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
